
import React from 'react';
import UseCaseImage from '../../images/higherUseCase.png'
const ArticleHigherEducationUseCase = () => {
  return (
    <div className="article-business-section">
      <div className="full-container-article">
        <h2 className="article-business-section-title">Use case</h2>
        <p className="article-business-section-text">
          Although colleges and universities are knowledge and idea hubs, it's
          essential to have distinct spaces between the three most prominent
          groups of people: students, professors, and the administration staff.
          Of course, the challenges are different for each group of users, but
          in the end, it will result in an overall better and more inclusive
          college experience for everyone. In our previous cases, we have
          noticed several problems that Agreed has been able to help in higher
          education institutions:
        </p>
        <p className="article-business-section-text">
          First and foremost, the students want change. It doesn't matter how
          great the institution is; to them, it can always be better. They have
          ideas to improve things, and they are not afraid to share. By
          implementing Agreed in your higher education organization, you'll see
          a boom of invaluable ideas. Ideas that promote inclusivity, diversity,
          innovation, and success.
        </p>
        <p className="article-business-section-text">
          In their designed workspace, students will be able to add their ideas,
          draft and pitch them to the administration departments in charge.
          These ideas can range from problems with the buildings to new projects
          and curriculum changes. Agreed will give the students a platform for
          their ideas to be heard and implemented, teaching them the vital
          entrepreneurship mindset and why self-confidence is essential for
          growth and success.
        </p>
        <p className="article-business-section-text">
          Agreed also is a platform for different students to share their ideas
          on life-changing projects and research that will help lead the change
          in the world. Students can pitch project ideas, calculate the funding,
          get the support and get the idea off the ground. Many universities
          already have research programs in place. Now it is easier for everyone
          to jointly decide which projects to greenlight.
        </p>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="article-business-section-text">
              For the professors in the colleges that implement Agreed, there is
              a separate workspace. Here, they can share their valuable ideas
              and draft and pitch them. They might have ideas about new subjects
              they want to add to the curriculum, new events to share ideas and
              help the students be more comfortable sharing their ideas.
            </p>
            <p className="article-business-section-text">
              Professors, in general, are great agents of change. Implementing
              the Agreed platform with their own workspace impacts how they will
              use it and encourage their students to use it. It's a great tool
              to get students to develop their entrepreneurial mindset.
            </p>
            <p className="article-business-section-text">
              The professors who have tried it have been quite happy with the
              results and feedback they have received and state that
              implementing Agreed in their classroom has led to a more
              outspoken, innovative, and diverse class than ever before.
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <img src={UseCaseImage} alt="articleImage" style={{ width: '100%' }} />
          </div>
        </div>
        <p className="article-business-section-text">
          They are also happy that they can share their own improvement ideas
          about budgeting, classes, experiments, and labs that would be financed
          by the school. The professors state that they have finally found a
          tool that not only promotes change and inclusivity, but it also gives
          them a chance to make meaningful changes, backed up by numbers and a
          whole process, that won't let a valuable idea disappear.
        </p>
        <p className="article-business-section-text">
          The last group of users for a college is the administration. The
          administration is responsible for the maintenance and supervision of
          the institution and is separate from the faculty or academics,
          although some personnel do have joint responsibilities.
        </p>
        <p className="article-business-section-text">
          Being responsible for the maintenance of the institution requires a
          lot of effort and the ability to analyze the day-to-day situations and
          figure out what's wrong or if anything needs to be replaced or
          improved.
        </p>
        <p className="article-business-section-text">
          With Agreed, this communication becomes a lot easier. First, the
          administration staff sees a problem, has an idea for a solution, and
          inputs it into the platform. From there, other administration members
          will add their own evaluations and calculations to make the idea a
          reality, minimizing the time it takes to fix an issue.
        </p>
        <p className="article-business-section-text">
          Whether it be about improving campus conditions, budgeting, or
          investing ideas, they can all put them into Agreed, so every idea gets
          the attention and buy-in it deserves. In addition, the platform is a
          great way for the staff to also be on the same page with the students
          and professors.
        </p>
        <p className="article-business-section-text">
          Since the administration is responsible for approving many of the
          changes and improvement ideas that both students and professors will
          have, it has a critical role in the implementation of the software and
          its success.
        </p>
        <p className="article-business-section-text">
          Typical Challenges that Agreed helps to solve:
          <ol>
            <li className="article-business-section-text">
              1. Professors want to add or change the curriculum
            </li>
            <li className="article-business-section-text">
              2. Professors have new ideas to test in new laboratories
            </li>
            <li className="article-business-section-text">
              3. Professors/departments want to be more involved in budgeting
              and decision making
            </li>
            <li className="article-business-section-text">
              4. Staff wants to improve campus conditions
            </li>
            <li className="article-business-section-text">
              5. Staff wants to know where to add more professors and make
              changes to budgets
            </li>
            <li className="article-business-section-text">
              6. Staff wants new ideas for change in curriculum, classes, and
              projects
            </li>
            <li className="article-business-section-text">
              7. Students want to improve their conditions
            </li>
            <li className="article-business-section-text">
              8.Students want to change certain classes and curriculums
            </li>
            <li className="article-business-section-text">
              9.Students want to be more involved in projects and research
            </li>
          </ol>
        </p>
      </div>
    </div>
  );
};
export default ArticleHigherEducationUseCase;
