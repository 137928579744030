import React from 'react';
import ArticleBusinessComponent from '../components/caseStudyComponent/articleBusinessComponent';
import ArticleHigherEducationUseCase from '../components/caseStudyComponent/articleCaseStudyHeigherEducation';
import Footer from '../components/footerComponent';
import Header from '../components/headerComponent';
import { graphql } from 'gatsby';
import ArticleHomeHigherComponent from '../components/caseStudyComponent/articalHomeHigherComponent';
const UseCaseHigherEducation = props => {
  return (
    <>
      <Header />
      <ArticleHomeHigherComponent articleTitle="Use Case" />
      <ArticleBusinessComponent
        articleTitle="Backstory"
        articleText="Every person is capable of having a valuable idea, no matter their age, position, or job. However, when it comes to colleges, universities, and all higher education institutions, we have noticed that it's a gold mine for great ideas, whether they are for improving industrial processes, changing the world, inventing a product, or even improving things inside the college itself."
        articleSubText="Who better than professors and students know what needs to be done inside a university? They are the ones with the analytical skills; they take notes, brainstorm, and solve problems on a daily basis. All these qualities can be harnessed to invoke significant change with Agreed. "
        articleDescriptionUseCase="Agreed is much more than an idea-sharing platform, though. It's an innovation and improvement tool capable of bringing immense growth and success to organizations. It has proven its success with companies and organizations of all sizes and industries, from hospitals to marketing and insurance companies. Now Agreed is bringing these waves of change into higher education."
        articleDescriptionUseCaseHigher="The pandemic hit the higher education sector really hard. Everything was moved online, processes had to be added or adapted, jobs were lost, and often a huge rift appeared between the students and the staff. Many great ideas for change and improvement were lost, weren't heard, or were ignored. Many of these colleges also suffered great losses in students, staff, and income."
        articleSubtDescriptionUseCaseHigher="Thankfully, they were able to recover, but not entirely, and they certainly need a lot of help, not only to return to their former glory but to surpass it and become even more extraordinary. By implementing Agreed throughout the administration and student body, many valuable ideas came to light that helped our clients achieve much more than other colleges in the US."
      />
      <ArticleHigherEducationUseCase />
      <Footer />
    </>
  );
};
export const query = graphql`
  query {
    wpPage(slug: { eq: "articlehighereducation" }) {
      articleHomeSection {
        articleHomeTitle
        articleHomeImage {
          localFile {
            childrenImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
export default UseCaseHigherEducation;
