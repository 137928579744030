import React from "react";

const ArticleHomeHigherComponent = (props)=>{
  const {articleTitle} = props;
    return (
      <div className="full-container">
      <div className="article-higher-section">
          <div className="article-section">
            <div className="article-section-text">
              <h1 className="article-section-text-title">{articleTitle}</h1>
            </div>
          </div>
</div>
      </div>
    );
}
export default ArticleHomeHigherComponent;