import React from 'react';
const ArticleBusinessComponent = props => {
  const {
    articleTitle,
    articleText,
    articleDescription,
    articleSubText,
    articleDescriptionUseCase,
    articleSubtDescriptionUseCaseHigher,
    articleDescriptionUseCaseHigher,
  } = props;
  return (
    <div className="article-business-section">
      <div className="full-container-article">
        <h2 className="article-business-section-title">{articleTitle}</h2>
        <p className="article-business-section-text">{articleText}</p>
        <p className="article-business-section-text">{articleSubText}</p>
        <p className="article-business-section-text">{articleDescription}</p>
        <p className="article-business-section-text">
          {articleDescriptionUseCase}
        </p>
        <p className="article-business-section-text">
          {articleDescriptionUseCaseHigher}
        </p>
        <p className="article-business-section-text">
          {articleSubtDescriptionUseCaseHigher}
        </p>
      </div>
    </div>
  );
};
export default ArticleBusinessComponent;
